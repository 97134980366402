

<div class="p-grid">
  <div class="p-col-12">
    <div style="background-color: #006b37;">
      <img src="assets/images/logo_4_0.png">
    </div>
  </div>
</div>

<div class="p-grid">
  <div class="p-col-12">
    <div> 
      
    </div>
  </div>
</div>

<div class="p-grid">
    <div class="p-col-12 p-md-8 p-lg-8" >
      <router-outlet></router-outlet>
    </div>
    <div class="p-col-12 p-md-4 p-lg-4" style="padding-left: 10px;">
        <p-card >
          <div [innerHTML] = "data_1"></div>      
        </p-card>
        <p-card >
          <div [innerHTML] = "data_2"></div>      
        </p-card>
    
    </div>
</div>


 
<div class="p-grid">
  <div class="p-col">
    <div class="footer">
      <div class="p-text-center" style="color: white;padding: 30px">
        <p>
          (c) Kiuruveden Näkökulma 2021
        </p>
        <p>
          Laaja valikoima laadukkaita kehyksiä joka lähtöön.
        </p>
        <div>
          <span style="margin-right: 10px;">
            <i class="pi pi-map-marker" ></i>
          </span><span>Kiurukatu 1, 74700 Kiuruvesi</span>
        </div>
      </div>
    </div>
  </div>
</div>



