<div class="p-grid">
    <div class="p-col-12">
        <p-card >
            <div class="p-grid">
                <div class="p-col-12">
                    <h2>Yhteystiedot</h2>
                    <hr>
                </div>
            </div>
            <div class="p-grid">
                <div class="p-col-12 p-md-6 p-lg-6">
                        <p>
                            Karhupuiston Optiika sijaitsee hyvien kulkuyhteyksien varrella Helsingin Kalliossa, Karhupuiston laidalla, aivan ydinkeskustan tuntumassa.

                            Tervetuloa hyvän palvelun optikkoliikkeeseen!

                            Sijaintimme kartassa:
                        </p>
                    </div>

                <div class="p-col-12 p-md-6 p-lg-6">
                    <img src="assets/images/nakokulma_julkisivu_rz.jpg" alt="" class="front">
                </div> 
            </div>
        </p-card>
    </div>
</div>
