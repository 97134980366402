<img src="assets/images/etusivu_0.png" alt="" class='front'>
<div class="p-text-center" style="margin-top: 20px;margin-bottom: 20px;color: darkgreen;">
    <hr>
    <h1>Kiuruveden Näkökulma</h1>
    <h2>
        <p>
            Optikon ja silmälääkärin palvelut - Silmälasit - Piilolasit - Korut - Kellot - Lahjatavarat
        </p>
    </h2>
    <div class="p-grid">
        <div class="p-col-2">

        </div>
        <div class="p-col-8">
            <h3>
                <p>
                    Meiltä myös silmälasien korjaus nopealla aikataululla, jopa odottaessa. Entisiin kehyksiin linssit myös noin 1-2 tunnin sisällä
        
                </p>
                <p>
                    Pariston vaihdot kelloihin useimmiten odottaessa
                </p>
            </h3>
        </div>
        <div class="p-col-2">
            
        </div>
    </div>
    
    <div class="p-grid">
        <div class="p-col-5"></div>
        <div class="p-col-2"><a href="https://www.facebook.com/profile.php?id=100054531966382" target="_blank">
            <img src="/assets/images/facebook.png" alt="" class="front">
            </a>
        </div>
        <div class="p-col-5"></div>
    </div>
    

</div>
<hr>



