import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {ButtonModule} from 'primeng/button';
import {MenubarModule} from 'primeng/menubar';
import {CardModule} from 'primeng/card';
import { EtusivuComponent } from './pages/etusivu/etusivu.component';
import { YhteystiedotComponent } from './pages/yhteystiedot/yhteystiedot.component';




@NgModule({
  declarations: [
    AppComponent,
    EtusivuComponent,
    YhteystiedotComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ButtonModule,
    MenubarModule,
    CardModule,
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
