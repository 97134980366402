import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EtusivuComponent } from './pages/etusivu/etusivu.component';
import { YhteystiedotComponent } from './pages/yhteystiedot/yhteystiedot.component';

const routes: Routes = [
  { path: "home", component: EtusivuComponent },
  { path: "contact",component: YhteystiedotComponent},
  { path: "", redirectTo: "/home",pathMatch: "full" }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
