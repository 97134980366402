import { Component } from '@angular/core';


import {MenuItem} from 'primeng/api';
import * as html2pdf from "html2pdf.js"

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title: string = 'nakokulma';
  items:  MenuItem[];
  text: string;
  text2: string;
  filename: string = "testing.pdf";

  data_1: string;
  data_2: string;


  ngOnInit(){
 
    this.data_1 = `<h2><span style="color: #008000;">Aukioloajat:</span></h2>
    <hr />
    <table style="height: 51px;" width="100%">
    <tbody>
    <tr>
    <td style="width: 13.5245%;">Ma - Pe</td>
    <td style="width: 82.4755%;">08.30 - 17.00</td>
    </tr>
    <tr>
    <td style="width: 13.5245%;">La</td>
    <td style="width: 82.4755%;">Suljettu</td>
    </tr>
    <tr>
    <td style="width: 13.5245%;">Su</td>
    <td style="width: 82.4755%;">Suljettu</td>
    </tr>
    </tbody>
    </table>`;

  	  this.data_2 = `<h2><span style="color: #008000;">Yhteystiedot:</span></h2>
      <hr />
      <table style="height: 51px;" width="100%">
      <tbody>
      <tr>
      <td style="width: 13.5245%;">
        <i class="pi pi-map-marker"></i>
      </td>
      <td style="width: 82.4755%;">Kiurukatu 1, 74700 Kiuruvesi</td>
      </tr>
      <tr>
      <td style="width: 13.5245%;">
        <i class="pi pi-envelope"></i>
      </td>
      <td style="width: 82.4755%;">Email</td>
      </tr>
      <tr>
      <td style="width: 13.5245%;">
        <i class="pi pi-phone"></i>
      </td>
      <td style="width: 82.4755%;">017 752277</td>
      </tr>
      </tbody>
      </table>
      <p>Kiuruveden Näkökulma sijatsee Kiuruveden ydinkeskustassa, torin laidalla.</p>
      <br><br>
      
      `;

    this.items = [{
      label:'Etusivu',
      icon:'pi pi-fw pi-home',
      routerLink: "/home"
  },
  {
      label:'Yritys & Palvelut',
      items:[
          {
              label:'Silmälasien korjaukset'
          },
          {
              label:'Linssit omiin kehyksiin',
          },
      ]
  },
  {
      label:'Tuotteet',
      items:[
          {
              label:'Silmälasikehykset',

          },
          {
              label:'Silmälasilinssit',

          },
          {
              label:'Piilolinssit',
          },
          {
            label:'Aurinkolasit',
        }
      ]
  },
  {
      label:'Tarjoukset',
  },
  {
      label:'Yhteystiedot',
      routerLink: "/contact"
  }
];

  }//ngoninit

  

  
}
